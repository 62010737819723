import { Outlet } from 'react-router-dom';

const CustomLayout = () => {
  return (
    <>
      <Outlet />
      {/* <Drift appId="tx4xdytw89uw" /> */}
    </>
  );
};

export default CustomLayout;
