const Footer = () => {
  return (
    <>
      Trazolab <br />
      Copyright ©2024
    </>
  );
};

export default Footer;
